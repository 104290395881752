<template>
  <span></span>
</template>

<script>
import axios from 'axios';
import myUser from '../myUser';
import { toast } from "vue3-toastify";
import 'vue3-toastify/dist/index.css'

export default {
  name: 'TasksApp',
  data() {
    
    return {
      lang: this.$i18n.locale,
      interval: null,
    }
  },
  methods: {
    async checkLogin() {
      if (myUser.isLogin == 'yes') {
        if (myUser.check_security_code == 'no') {
          this.getDataView();
        }
      }
    },
    async getDataView(){
      this.checkStatusUser();
      this.checkNotifications();
      // this.checkMaintenance();
      // this.getVersionApp();
    },
    async checkStatusUser(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('auth.php?action=checkStatusUser', inputs).then((res) => {
        // console.log("checkStatusUser", res['data'])
        if (res['data']['msg'] !== 'ok') {

          localStorage.removeItem("MY_USER_BROSCASH");
          toast.success(this.$t('_home._success_logout'), {autoClose: 1000,});
          this.isLoading = false;

          setTimeout(() => {
            location.reload();
          }, 1000);

        }
      }).catch(() => { 
        // _changLocalUser("isDisconnect", "network");
      });
    },
    async checkNotifications(){
      this.getNotifications();
      this.interval = setInterval(() => {
        this.getNotifications();
      }, 20000);
    },
    async getNotifications(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('notifications.php?action=getNotifications', inputs).then((res) => {
        // console.log("getNotifications", res['data'])
        if (res['data']['msg'] == 'ok') {
          res['data']['items'].forEach(pop => {
            let id_message  = pop['ID'];
            let title       = pop['Title'];
            let message     = pop['Message'];

            // const dddd = {'id_message': id_message, 'title': title, 'message': message } 
            // console.log(dddd)
            this.sendNotification(id_message, title, message);
            

          });
        }
      }).catch(() => { 
        // _changLocalUser("isDisconnect", "network");
      });
    },
    async raedNotifications(_id_message){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'idNot': _id_message} 
      await axios.postForm('notifications.php?action=raedNotifications', inputs).then(() => {
        // console.log("raedNotifications", res)
      }).catch(() => { 
        // _changLocalUser("isDisconnect", "network");
      });
    },
    async sendNotification(_id_message, _title, _body){
      const permission = await Notification.requestPermission();
      if(permission){
        const _icon = "https://www.bros-cash.com/aaa/images/web/icon.png";
        const _tag   = "MESSAGE" + new Date().getTime();
        const notification = new Notification(_title, {
          tag: _tag,
          body: _body,
          icon: _icon,
          // image: _icon,
          vibrate: 500,
        });

        notification.addEventListener("show", () => {
          this.raedNotifications(_id_message);
          // console.log("Notification displayed", notification);
        });
      }
    },
  },
  mounted(){
    this.checkLogin();
  }
}
</script>


<style scoped>
</style>
