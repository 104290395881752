import { createI18n } from 'vue-i18n'
import us from './locales/us.json'
import ae from './locales/ae.json'
import tr from './locales/tr.json'

function loadLocaleMessages() {
  const locales = [{ us: us }, { ae: ae }, { tr: tr }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key] 
  })
  return messages
}

function getStartingLocale() {
  if (localStorage.getItem('MY_LANG_BROSCASH')) {

    if(localStorage.getItem('MY_LANG_BROSCASH') === 'ae'){
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
    }
    
    return localStorage.getItem('MY_LANG_BROSCASH')
  }
  return "us"
}

export const _getStartingLocale = () => {
  if (localStorage.getItem('MY_LANG_BROSCASH')) {

    if(localStorage.getItem('MY_LANG_BROSCASH') === 'ae' ){
      document.body.classList.remove("ltr");
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      document.body.classList.add("ltr");
    }
    
    return localStorage.getItem('MY_LANG_BROSCASH')
  }
  return "us"
}

export const _changeSwitchLang = (lang) => {
  // console.log("change", lang)
  // console.log("change", localStorage.getItem('MY_LANG_BROSCASH'))
  // if (localStorage.getItem('MY_LANG_BROSCASH')) {
    if (localStorage.getItem('MY_LANG_BROSCASH') !== lang) {
      localStorage.setItem("MY_LANG_BROSCASH", lang);
      if(lang === 'ae'){
        document.body.classList.remove("ltr");
        document.body.classList.add("rtl");
      } else {
        document.body.classList.remove("rtl");
        document.body.classList.add("ltr");
      }
      setTimeout(() => {
        location.reload();
      }, 100);
    }
  // }
}

export default createI18n({
  locale: getStartingLocale() || 'us', //localStorage.getItem('MY_LANG_BROSCASH') || 'en',
  fallbackLocale: getStartingLocale() || 'us', //localStorage.getItem('MY_LANG_BROSCASH') || 'en',
  messages: loadLocaleMessages()
})