<template>
  <SpinnerComponent :start="isLoading" />
  <div class="p--home">

    <swiper v-if="isAds" :slides-per-view="1" :space-between="50" :autoplay="{delay: 3500, disableOnInteraction: false}" :modules="modules" class="box-ads-swiper">
      <swiper-slide v-for="a in ads" :key="a" class="item-ads-swiper">
        <img alt="default" :src="a['Image']">
      </swiper-slide>
    </swiper>

    <div class="group-settings" v-if="isLogin == 'yes'">
      <div class="box-marquee" v-if="isNews">
        <marquee direction="right">
          <span class="text" v-for="n in news" :key="n">{{ n.Details }} <span class="m"></span></span>
        </marquee>
      </div>
      <div v-if="!isNews" style="opacity: 0;">.</div>

      <div class="btns">
        <span class="btn-settings" @click="goToPage('notifications')"><ion-icon class="icon" name="notifications-outline"></ion-icon></span>
        <!-- <span class="btn-settings" @click="goToPage('messages')"><ion-icon class="icon" name="mail-outline"></ion-icon></span> -->
      </div>
    </div>

    <div class="box-search">
      <input class="search-input" type="search" :placeholder="$t('_public._search')" @input="filter($event)">
    </div>


    <!-- <div class="content-category-parent" v-if="isCategoriesParent">
      <div class="item-category-parent" v-for="x in categoriesParent" :key="x" @click="say(x)">
        <img class="category-parent-img" alt="Vue logo" :src="x.Image">
        <div class="info">
          <h3 class="category-parent-name">{{ x.Name }}</h3>
          <p class="category-parent-details">{{ x.Details }}</p>
        </div>
        <span>{{ x.countItems }}</span>
      </div>
    </div>

    <div class="content-empty-items" v-if="!isCategoriesParent">
      <div><ion-icon name="cloud-offline-outline"></ion-icon></div>
      <p class="products-name">{{ $t('_public._there_are_no_data') }}</p>
    </div> -->



    
    <div class="content-category" v-if="isCategories">
      <!-- <div class="item-category" v-for="x in 100" :key="x" @click="say(x)"> -->
      <div class="item-category" v-for="x in categories" :key="x" @click="say(x)">
        
        <!-- <div v-if="x['ribbon_item'] !== 'no'" class="card-ribbon" :class="x['ribbon_item']">
          <span>{{ x['ribbon_text'] }}</span>
        </div> -->

        <img class="category-img" alt="Vue logo" :src="x.Logo">
        <h3 class="category-name">{{ x.Name }}</h3>
      </div>
    </div>

    <div class="content-empty-items" v-if="!isCategories">
      <div><ion-icon name="cloud-offline-outline"></ion-icon></div>
      <p class="products-name">{{ $t('_public._there_are_no_data') }}</p>
    </div>



  </div>

  
  <TasksApp/>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import TasksApp from '@/components/TasksApp.vue';

import axios from 'axios';
import router from "@/router";

import { Swiper, SwiperSlide } from 'swiper/vue';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import myUser, { _changLocalUser } from '../myUser'
import { routerVisitor } from '../auth'
import { showBtnSidebar, showNavigation } from '@/components/sidebar/state';
import Swal from 'sweetalert2'
// import { toast } from "vue3-toastify";
// import 'vue3-toastify/dist/index.css'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    SpinnerComponent,
    TasksApp,
    Swiper,
    SwiperSlide
  },
  data() {
    
    return {
      lang: this.$i18n.locale,
      isLogin: myUser.isLogin,
      isLoading: true,
      categoriesParent: null,
      isCategoriesParent: false,
      categories: [],
      b_categories: [],
      isCategories: false,

      ads: null,
      isAds: false,
      swiper: null,

      news: null,
      isNews: false
    }
  },
  methods: {
    async checkLogin() {

      const check = routerVisitor();
      if(check == 'yes'){
        this.getDataView()
      }

    },
    async getDataView(){

      // this.getAllCategoriesParent();
      this.getAllCategories();
      this.getAllViewAds();
      this.getAllViewNews();
      this.checkMaintenance();
      this.getVersionApp();

      if(myUser.isLogin == 'yes'){
        this.getAllMessagesPop();
        this.getExchangeRates();
        this.matchingPriceGroups() 
      }
  
    },
    async getAllCategoriesParent(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('categories.php?action=getAllCategoriesParent', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.categoriesParent = res['data']['items'];
          this.isCategoriesParent = true;
          this.isLoading = false;

        } else {
          this.isCategoriesParent = false;
          // console.log(res['data']['msg']);
          this.isLoading = false;
        }
      }).catch(() => { _changLocalUser("isDisconnect", "network") });
    },
    async getAllCategories(){
      // this.isLoading = false;

      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'idCatParent': this.$route.params.id} 
      await axios.postForm('categories.php?action=getAllCategories', inputs).then((res) => {
        // console.log(res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.categories   = res['data']['items'];
          this.b_categories = res['data']['items'];
          this.isCategories = true;
          this.isLoading = false

        } else {
          this.categories   = [];
          this.b_categories = [];
          this.isCategories = false;
          // console.log(res['data']['msg']);
          this.isLoading = false
        }
      }).catch(() => {
        // console.error('Login request error:', error);
        // console.error('Login request error:');
        _changLocalUser("isDisconnect", "network")
      });
    },
    async getAllViewAds(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('ads.php?action=getAllViewAds', inputs).then((res) => {
        // console.log("getAllViewNews", res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.ads     = res['data']['ads'];
          this.isAds   = true;
          this.isLoading= false;

        } else {
          this.isAds = false;
          // console.log(res['data']['msg']);
          this.isLoading = false;
        }
      }).catch(() => { _changLocalUser("isDisconnect", "network") });
    },
    async getAllViewNews(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('ads.php?action=getAllViewNews', inputs).then((res) => {
        // console.log("getAllViewNews", res['data'])
        if (res['data']['msg'] == 'ok') {
          // console.log(res)
          // console.log(res['data']['items'])
          this.news     = res['data']['ads'];
          this.isNews   = true;
          this.isLoading= false;

        } else {
          this.isNews = false;
          // console.log(res['data']['msg']);
          this.isLoading = false;
        }
      }).catch(() => { _changLocalUser("isDisconnect", "network") });
    },
    async getAllMessagesPop(){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('messages.php?action=getAllMessagesPop', inputs).then((res) => {
        // console.log("getAllMessagesPop", res['data'])
        if (res['data']['msg'] == 'ok') {
          res['data']['items'].forEach(pop => {
            let id_message  = pop['ID'];
            let title       = pop['Title'];
            let message     = pop['Message'];
            // let in_image  = pop['in_Image'];
            let image       = pop['Image'];

            this.viewPop(id_message, title, message, image)

          });


        } else {
          // console.log(res['data']['msg']);
        }
      }).catch(() => { _changLocalUser("isDisconnect", "network") });
    },
    async viewPop(id_message, title, text, imageUrl){
      Swal.fire({
        title: title, //'Sweet!',
        text: text, //'Modal with a custom image.',
        imageUrl: imageUrl, //'https://unsplash.it/400/200',
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        showClass: {
          popup: 'messages__pop'
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("viewPop")
          this.readMessagesPop(id_message);
        }
      })
    },
    async readMessagesPop(id_message){
      // this.isLoading = false;
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'id_message': id_message} 
      await axios.postForm('messages.php?action=readMessagesPop', inputs).then((res) => {
        console.log("readMessagesPop", res['data'])
      }).catch(() => { 
        //_changLocalUser("isDisconnect", "network") 
      });
    },
    async checkMaintenance(){
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('app_settings.php?action=checkMaintenance', inputs).then((res) => {
        // console.log("getAllMessagesPop", res['data'])
        if (res['data']['msg'] == 'ok') {

          _changLocalUser("isDisconnect", "maintenance");
          
        }
      }).catch(() => { 
        //_changLocalUser("isDisconnect", "network") 
      });

    },
    async getVersionApp() {
      let app_ver   = this.$t('_public._app_ver');
      const inputs  = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token, 'ver': app_ver} 
      await axios.postForm('app_settings.php?action=getVersionAppNew', inputs).then((res) => {
        // console.log("getAllMessagesPop", res['data'])
        if (res['data']['status'] == 'update') {
          _changLocalUser("isDisconnect", "update");
          // router.push({ path: `/disconnect/update` });
        }

      }).catch(() => { 
        //_changLocalUser("isDisconnect", "network") 
      });
    },
    async getExchangeRates(){
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('exchange_rates.php?action=getExchangeRates', inputs).then(() => {
        // console.log("getExchangeRates", res['data'])
      }).catch(() => { 
        //_changLocalUser("isDisconnect", "network") 
      });
    },
    async matchingPriceGroups(){
      const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
      await axios.postForm('price_groups.php?action=matchingPriceGroups', inputs).then(() => {
        // console.log("matchingPriceGroups", res['data'])
      }).catch(() => { 
        //_changLocalUser("isDisconnect", "network") 
      });
    },
    // async getAllCategories(){
    //   // this.isLoading = false;
    //   const inputs = {'lang': this.lang, 'idus': myUser.id_user, 'token': myUser.token} 
    //   await axios.postForm('categories.php?action=getAllCategories', inputs).then((res) => {
    //     console.log(res['data'])
    //     if (res['data']['msg'] == 'ok') {
    //       // console.log(res)
    //       console.log(res['data']['items'])
    //       this.categories = res['data']['items'];
    //       this.isCategories = true;

    //     } else {
    //       this.isCategories = false;
    //       console.log(res['data']['msg']);
    //       // this.isLoading = true
    //     }
    //   }).catch(() => {  });
    // },
    async goToPage(page){
      // console.log(page);
      router.push({ path: `/${page}` })
    },
    filter (eve){
      let text = eve.target.value;
      this.categories = this.b_categories;

      if(text.length & this.categories.length){
        // console.log(eve.target.value)
        const n_categories = this.categories.filter((item) =>
          item.Name.toLowerCase().includes(eve.target.value.toLowerCase().split(" "))
        );
        // console.log(n_categories)
        this.categories = n_categories;

      } else {
        return this.categories;
      }
    }
  },
  mounted(){
    showBtnSidebar()
    showNavigation()
    router.replace()
    this.checkLogin()

  },
  setup() {
    const say = (item) => {
      const iditem = item['ID'];
      // router.push({ path: `/categories/${iditem}` })
      router.push({ path: `/items/${iditem}` })
    }

    return {
      say,
      modules: [Autoplay, Pagination, Navigation],
    };
  },
}
</script>
<style>
.group-settings {
  background: var(--box-background);
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 5px 12px rgba(var(--color-box-shadow-rgb), 0.08);
}
.group-settings h5 {
  font-size: 13px;
  color: var(--color-text);
}
.group-settings .btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.group-settings .btns .btn-settings {
  min-width: 34px;
  height: 34px;
  padding: 0px 7px;
  border: 1px solid var(--public);
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 5px;
  margin: 0px 3px;
  cursor: pointer;
  color: var(--color-text);
}
.group-settings .btns .btn-settings .icon:hover  {
  transform: scale(1.1);
}
.group-settings .btns .btn-settings .icon {
  font-size: 16px;
}
/* .box-ads-swiper {
}
.item-ads-swiper {
} */
.box-ads-swiper {
  border-radius: 15px;
}
.box-ads-swiper .item-ads-swiper{
  border-radius: 15px;
}
.box-ads-swiper .item-ads-swiper img {
  max-height: 320px;
  width: 100%;
  border-radius: 15px;
}
.box-marquee {
  background: var(--box-background);
  /* margin: 12px 0px; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  /* padding: 12px; */
  border-radius: 5px;
  box-shadow: 12px 5px 12px rgba(var(--color-box-shadow-rgb), 0.05);
  /* min-height: 46px; */
  min-height: 34px;
  width: calc(100% - 90px);
}
.box-marquee marquee {
  direction: initial;
}
.box-marquee marquee .text .m {
  padding: 0px 12px;
}
.box-search {
  background: var(--box-background);
  margin: 12px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0px 5px 12px rgba(var(--color-box-shadow-rgb), 0.05);
  min-height: 46px;
}
.search-input {
  width: 100%;
  outline: none;
}
.content-category-parent {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0px;
}
.content-category-parent .item-category-parent {
  position: relative;
  min-height: 94px;
  background: var(--box-background);
  width: 100%;
  padding: 15px;
  margin-bottom: 2%;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.08);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  transition: 0.2s;
}
.content-category-parent .item-category-parent:hover {
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.18);
  transform: scale(1.01);
  transition: 0.2s;
}
.content-category-parent .item-category-parent .category-parent-img {
  min-height: 52px;
  height: 52px;
}
.content-category-parent .item-category-parent .info {
  padding: 0px 12px;
  width: calc(100% - 102px);
  text-align: start;
}
.content-category-parent .item-category-parent .info .category-parent-name {
  font-size: 13px;
  color: var(--color-text);
}
.content-category-parent .item-category-parent .info .category-parent-details {
  font-size: 12px;
  color: var(--color-text);
}
.content-category-parent .item-category-parent span {
  width: 50px;
    height: 50px;
    border-radius: 15px;
    display: flex;
    box-shadow: 9px 0px 9px rgba(var(--color-box-shadow-rgb), 0.08);
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    color: var(--public);
    font-weight: 600;
}




.content-category {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0px;
}
.content-category .item-category {
  position: relative;
  min-height: 120px;
  background: var(--box-background);
  width: calc(92% / 3);
  padding: 9px;
  margin-bottom: 3%;
  border-radius: 9px;
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.05);
  cursor: pointer;
}
.content-category .item-category:hover {
  box-shadow: 0px 5px 20px rgba(var(--color-box-shadow-rgb), 0.15);
}
.content-category .item-category .category-img {
  width: 100%;
  border-radius: 12px;
}
.content-category .item-category .category-name {
  color: var(--color-text);
  font-size: 12px;
  padding-top: 5px;
}
/* ====> START Screen Phone <====*/
@media (min-width: 1px) {
  .content-products .item-products {
    width: calc(92% / 3);
    margin-bottom: 3%;
  }
}
@media (max-width: 599px) {
  .p--home .content-category .item-category {
    width: calc(92% / 3);
    margin-bottom: 3%;
  }
}
@media (min-width: 600px) {
  .p--home .content-category .item-category {
    width: calc(92% / 3);
  }
}
@media (min-width: 768px) {
  .p--home .content-category .item-category {
    width: calc(92% / 4);
  }
}
@media (min-width: 992px) {
  .p--home .content-category .item-category {
    width: calc(92% / 4);
  }
}
@media (min-width: 1200px) {
  .p--home .content-category .item-category {
    width: calc(92% / 5);
  }
}
/* ====> END Screen Phone <====*/
</style>
