import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import i18n from './i18u'
import myUser from './myUser'

import axios from 'axios'

import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
const vuetify = createVuetify({
    components,
    directives,
})




// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, PATCH, PUT, DELETE, OPTIONS';
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Content-Type, Authorization';
axios.defaults.headers.common['Content-type'] = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://www.bros-cash.com/aaa/api_web/';

createApp(App).use(router).use(i18n).use(myUser).use(vuetify).use(bootstrap).mount('#app')
// createApp(App).use(router).use(i18n).use(myUser).use(bootstrap).mount('#app')
