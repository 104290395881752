<template>
  <div class="bottom-navigation noPrint"  v-if="isDisplayNavigation">

    <div class="back-footer-links" v-if="isLogin === 'yes'"></div>
    <div class="footer-links"  v-if="isLogin === 'yes'">
      <div class="links">
      <v-btn class="btn-tab" :class="[active == 'tab01' ? 'active' : '', errorClass]" value="tab01" @click="goToPage('tab01' ,'profile')">
        <ion-icon class="icon" name="person-outline"></ion-icon>
      </v-btn>
      <v-btn class="btn-tab" :class="[active == 'tab02' ? 'active' : '', errorClass]" value="tab02" @click="goToPage('tab02' ,'favorite')">
        <ion-icon class="icon" name="heart-outline"></ion-icon>
      </v-btn>
      <v-btn class="btn-tab" :class="[active == 'tab03' ? 'active' : '', errorClass]" value="tab03" @click="goToPage('tab03' ,'')">
        <ion-icon class="icon" name="home-outline"></ion-icon>
      </v-btn>
      <v-btn class="btn-tab"  :class="[active == 'tab04' ? 'active' : '', errorClass]" value="tab04" @click="goToPage('tab04' ,'orders')">
        <ion-icon class="icon" name="server-outline"></ion-icon>
      </v-btn>
      <v-btn class="btn-tab" :class="[active == 'tab05' ? 'active' : '', errorClass]" value="tab05" @click="goToPage('tab05' ,'wallet')">
        <ion-icon class="icon" name="wallet-outline"></ion-icon>
      </v-btn>
      <div class="indicator"></div>
    
    </div>
  </div>

</div>
</template>

<script>
import { isDisplayNavigation } from '@/components/sidebar/state';
import myUser from '../myUser';
import router from "@/router";

export default {
  name: 'BottomNavigation',
  props: {
    msg: String
  },
  data() {
    return {
      active: 'tab03',
      isLogin: myUser.isLogin
    }
  },
  
  components: { 
    // SidebarLink,
    // color () {
    //   switch (this.value) {
    //     case 0: return 'blue-grey'
    //     case 1: return 'teal'
    //     case 2: return 'brown'
    //     case 3: return 'indigo'
    //     default: return 'blue-grey'
    //   }
    // }, 
  },
  methods: {
    async goToPage(tabs, page){
      this.active = tabs
      // console.log(page);
      router.push({ path: `/${page}` })
    }
    // async toggleMenu(){ // btn_toggle_menu
    //   if(document.getElementById("btn_toggle_menu")){
    //     // const btn_toggle_menu = document.getElementById("btn_toggle_menu");
    //     // btn_toggle_menu.classList.toggle('active');
    //     // console.log("toggleMenu", btn_toggle_menu);
    //     // toggleSidebar()
    //     // console.log(isDisplaySidebar.value)
    //     if(isDisplaySidebar.value) {
    //       hideSidebar()
    //     } else {
    //       showSidebar()
    //     }
    //   }
    // }
  },
  setup() {
    return { 
      isDisplayNavigation
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bottom-navigation {
  display: none;
}
.c--navbar {
  position: sticky;
  top: 0;
  /* left: 0; */
  z-index: 9999;
}
.content-navbar {
  position: relative;
  height: 65px;
  padding: 15px;
  background: var(--box-background);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.content-navbar .navbar-start,
.content-navbar .navbar-end {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.btn-toggle-menu {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-toggle-menu .l-first,
.btn-toggle-menu .l-second,
.btn-toggle-menu .l-third {
  width: 20px;
  height: 2px;
  background: var(--color-text);
  display: block;
  border-radius: 2px;
  transition: all 0.2s;
  animation: scales 0.6s;
}
.btn-toggle-menu .l-second{
  margin: 3px 0px;
}
.content-navbar .navbar-start .logo {
  width: 48px;
  padding-inline-start: 15px;
}
.content-navbar .navbar-start .name-app {
  font-size: 14px;
  color: var(--color-text);
  margin: 0;
  padding-inline-start: 9px;
  font-weight: normal;
}
.btn-toggle-menu.active .l-second {
  display: none;
}
.btn-toggle-menu.active .l-first {
  transform: rotateZ(-45deg);
  animation: rotateFirst 0.5s;
}
.btn-toggle-menu.active .l-third {
  transform: rotateZ(45deg);
  animation: rotateThird 0.5s;
}

.navbar-end .link {
  padding: 1em 1em 1em 0.25em;
  font-size: 12px !important;
  margin-inline-start: 0.5em;
}
/* ====> START BTN MENU <====*/
@keyframes rotateFirst {
  0%{
    transform: rotateZ(0deg);
  }
  35%{
    transform: rotateZ(180deg);
  }
  70%{
    transform: rotateZ(360deg);
  }
  100%{
    transform: rotateZ(-45deg);
  }
}
@keyframes rotateThird {
  0%{
    transform: rotateZ(360deg);
  }
  35%{
    transform: rotateZ(90deg);
  }
  70%{
    transform: rotateZ(180deg);
  }
  100%{
    transform: rotateZ(45deg);
  }
}
@keyframes scales {
  0%{
    transform: scale(0.3)
  }
  30%{
    transform: scale(1.2)
  }
  60%{
    transform: scale(0.9)
  }
  100%{
    transform: scale(1)
  }
}
/* ====> END BTN MENU <====*/

.back-footer-links {
  width: 100%;
  height: 80px;
}
.footer-links {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 999;
}
.links {
  position: relative;
  border: none;
  background: var(--public);
  contain: none;
  border-top: 6px solid var(--bg-background);
}
.btn-tab {
  background: transparent;
  position: relative;
  max-width: 70px;
  min-width: 56px !important;
  height: 56px !important;
  /* transition: 0.5s; */
  box-shadow: unset;
  background: var(--public);
  border-radius: 50%;
  color: #fff;
}
.btn-tab .icon {
  color: #fff;
  font-size: 20px;
}
.btn-tab.active {
  width: 56px;
  color: #fff;
  border-radius: 50%;
  transform: translateY(-20px);
  border: 3px solid var(--bg-background);
  z-index: 1;
}
.btn-tab.active .icon {
  color: #fff;
  position: relative;
  /* top: 4px; */
}
.indicator {
  position: absolute;
  top: -20px;
  left: calc(50% - 28px);
  width: 56px;
  height: 56px;
  background: var(--color-tex);
  border-radius: 50%;
  border: 6px solid var(--bg-background);
  transition: 0.5s;
}
.indicator::before {
  content: "";
  position: absolute;
  top: 14px;
  left: -18px;
  width: 12px;
  height: 12px;
  background:transparent;
  box-shadow: 6px -6px 0px 0px var(--bg-background);
  border-radius: 50%;
}
.indicator::after {
  content: "";
  position: absolute;
  top: 14px;
  right: -18px;
  width: 12px;
  height: 12px;
  background:transparent;
  box-shadow: -6px -6px 0px 0px var(--bg-background);
  border-radius: 50%;
}

.btn-tab:nth-child(1).active ~ .indicator {
  transform: translateX(calc(-56px * 2));
}
.btn-tab:nth-child(2).active ~ .indicator {
  transform: translateX(calc(-56px * 1));
}
.btn-tab:nth-child(3).active ~ .indicator {
  transform: translateX(calc(56px * 0));
}
.btn-tab:nth-child(4).active ~ .indicator {
  transform: translateX(calc(56px * 1));
}
.btn-tab:nth-child(5).active ~ .indicator {
  transform: translateX(calc(56px * 2));
}

@media (max-width: 992px) {
  .bottom-navigation {
    display: block;
  }
}
</style>
